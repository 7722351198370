/* eslint-disable import/no-unresolved */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Document, Thumbnail } from 'react-pdf';
import { DocumentCallback, OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";
import { Box, Typography } from "@mui/material";

import './AnnotationLayer.css';
import './TextLayer.css';

import FormPage from "../../molecules/FormPage";
import { RootState } from "../../../configureStore";
import Icon from "../../atoms/Icon";
import globalStyles from "../../../globalStyles";

interface FormImagesParams {
}

const FormImages = ({
}: FormImagesParams) => {
    const isMobile = useSelector<RootState, boolean>(({ status: { isMobile: im } }) => im || false);
    const b64 = useSelector<RootState, string | undefined>(({ bus: { b64: base } }) => base);
    const pdfUrl = useSelector<RootState, string | undefined>(({ bus: { pdfUrl: url } }) => url);
    const hasRequest = useSelector<RootState, boolean>(({ bus: { request } }) => Boolean(request?.FICode && request.irsIncomeID));
    const processing = useSelector<RootState, boolean>(({ bus: { processing: pr } }) => pr);

    const [numPages, setNumPages] = useState<number | null>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [retryRender, setRetryRender] = useState<number>(0);
    const [rendered, setRendered] = useState<boolean>(false);

    useEffect(() => {
        if (!pdfUrl) {
            setPageNumber(1);
            setNumPages(null);
        }
    }, [pdfUrl]);

    const onDocumentLoadSuccess: OnDocumentLoadSuccess = (document: DocumentCallback): void => {
        const { numPages: numPage } = document;
        setNumPages(numPage);
    };

    return (b64 && pdfUrl) ? (
        <Box sx={[globalStyles.flexCenter, globalStyles.col, globalStyles.box, globalStyles.darkBackground]}>
            <Icon
                name="Refresh"
                onClick={() => { setRetryRender(retryRender + 1); }}
                iconButton={{
                    sx: {
                        alignSelf: 'flex-end',
                    },
                }}
            />
            <Box
                sx={[globalStyles.flexCenter]}
            >
                <Box sx={[globalStyles.flexEndEnd, { alignSelf: 'flex-start', height: 200 }]}>
                    <Icon
                        name="ArrowLeft"
                        color={pageNumber === 1 ? "disabled" : "primary"}
                        sx={{
                            height: 64,
                            width: 64,
                        }}
                        iconButton={{
                            id: 'go-left',
                            disabled: pageNumber === 1,
                        }}
                        onClick={() => {
                            if (pageNumber !== 1) {
                                setPageNumber(pageNumber - 1);
                                setRendered(false);
                            }
                        }}
                    />
                </Box>

                <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderMode="canvas"
                    className="flex-center-center col"
                    onError={(err) => {
                        console.error(err);
                    }}
                >
                    <Box
                        sx={[
                            globalStyles.flexCenterStart,
                            { marginTop: isMobile ? 0 : -13 },
                            globalStyles.darkBackground,
                            globalStyles.pt1,
                            globalStyles.px1,
                            globalStyles.brXs,
                            {
                                maxWidth: '600px',
                                overflowX: 'scroll',
                            },
                        ]}
                    >
                        {
                            [...Array(numPages || 0).keys()].map((pn) => (
                                <Thumbnail
                                    pageNumber={pn + 1}
                                    height={156}
                                    className="mx-1 mb-2"
                                    onClick={() => {
                                        setPageNumber(pn + 1);
                                    }}
                                    key={`thumbnailNumber-${pn}`}
                                />
                            ))
                        }
                    </Box>
                    <Box sx={{ position: 'absolute', top: 100, right: '25%' }}>
                        Page {pageNumber} / {numPages}
                    </Box>
                    <FormPage
                        pageNumber={pageNumber}
                        retryRender={retryRender}
                        rendered={rendered}
                        setRendered={setRendered}
                    />
                </Document>
                <Box sx={[globalStyles.flexEndEnd, { alignSelf: 'flex-start', height: 200 }]}>
                    <Icon
                        name="ArrowRight"
                        color={numPages === pageNumber ? "disabled" : "primary"}
                        sx={{
                            height: 64,
                            width: 64,
                        }}
                        iconButton={{
                            id: 'go-right',
                            disabled: numPages === pageNumber,
                        }}
                        onClick={() => {
                            if (numPages !== pageNumber) {
                                setPageNumber(pageNumber + 1);
                                setRendered(false);
                            }
                        }}
                    />
                </Box>
            </Box>
            <p>
                Page {pageNumber} of {numPages}
            </p>
        </Box>
    ) : (
        <Box sx={[globalStyles.flexCenter, globalStyles.col, globalStyles.box, globalStyles.darkBackground]}>
            {
                hasRequest
                    ? <Typography>Downloading PDF</Typography>
                    : processing
                        ? <Typography>Waiting for a Request</Typography>
                        : <Typography>Click &ldquo;Next Request&ldquo; to start processing</Typography>
            }
        </Box>
    );
};

export default FormImages;
